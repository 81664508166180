<template>
  <div>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="projetos"
        :loading="loading"
        :options.sync="options"
        :server-items-length="pageable.totalElements"
        hide-default-footer
        dense
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title> Projetos Finalizados</v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- <v-btn
              color="primary"
              dark
              class="square"
              @click="projetoDialog = true"
              title="Novo Projeto"
              ><v-icon>{{ mdiPlus }}</v-icon></v-btn
            > -->
          </v-toolbar>
        </template>
        <template v-slot:[`item.valorTotalCliente`]="{ item }">
          <span>{{ item.projeto.valorTotalCliente | formataMoeda }}</span>
        </template>
        <template v-slot:[`item.valorTotalRealizado`]="{ item }">
          <span>{{ item.projeto.valorTotalRealizado | formataMoeda }}</span>
        </template>
        <template v-slot:[`item.receita`]="{ item }">
          <span>{{ item.projeto.receita | formataMoeda }}</span>
        </template>
        <template v-slot:[`item.parcelasRecebidas`]="{ item }">
          <span
            style="font-weight: bold"
            :style="{
              color: getColor(
                item.projeto.parcelasRecebidas,
                item.projeto.valorTotalCliente
              ),
            }"
            >{{ item.projeto.parcelasRecebidas | formataMoeda }}</span
          >
        </template>
        <template v-slot:[`item.parcelasPagas`]="{ item }">
          <span
            style="font-weight: bold"
            :style="{
              color: getColor(
                item.projeto.parcelasPagas,
                item.projeto.valorTotalRealizado
              ),
            }"
            >{{ item.projeto.parcelasPagas | formataMoeda }}</span
          >
        </template>
        <template v-slot:[`item.aceito`]="{ item }">
          <v-chip
            :color="item.aceito ? 'success darken-2' : 'error darken-2'"
            dark
            x-small
          >
            <strong>{{ item.aceito ? "Sim" : "Não" }}</strong>
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-switch
            v-model="item.projeto.finalizado"
            @click="finalizar(item)"
            hide-details
            dense
          ></v-switch>
        </template>

        <template v-slot:[`item.atividadesBasicas`]="{ item }">
          <v-speed-dial
            v-model="item.dial"
            :direction="'left'"
            :open-on-hover="false"
            :transition="'slide-y-reverse-transition'"
          >
            <template v-slot:activator>
              <v-btn
                v-model="item.dial"
                color="blue darken-2"
                dark
                x-small
                fab
                style="pt-5"
              >
                <v-icon v-if="item.dial"> mdi-close </v-icon>
                <v-icon v-else> mdi-plus </v-icon>
              </v-btn>
            </template>
            <v-btn
              fab
              dark
              x-small
              color="error"
              @click="excluir(item)"
              title="Exluir"
              ><v-icon> {{ mdiClose }} </v-icon>
            </v-btn>

            <v-btn
              fab
              dark
              x-small
              :color="'primary darken-2'"
              @click="editar(item)"
              :title="'Editar'"
              ><v-icon dark> {{ mdiPencil }} </v-icon>
            </v-btn>
            <v-btn
              fab
              dark
              x-small
              color="teal"
              @click="enviarEmail(item)"
              title="Enviar email"
              :disabled="!item.orcamentoId"
            >
              <v-icon>{{ mdiEmailSendOutline }}</v-icon>
            </v-btn>
            <v-btn
              fab
              dark
              x-small
              color="primary"
              @click="gerarContrato(item)"
              title="Contrato"
              :disabled="!item.orcamentoId"
            >
              <v-icon>{{ mdiFileDocumentEditOutline }}</v-icon>
            </v-btn>
            <v-btn
              fab
              dark
              x-small
              :color="'warning'"
              @click="atividadeBasica(item)"
              :title="'Custos Extras'"
              ><v-icon dark> {{ mdiCashMultiple }} </v-icon>
            </v-btn>
            <v-btn
              fab
              dark
              x-small
              @click="abreFormaPagamentoMensal(item)"
              :title="'Forma de Pagamento'"
              :color="'success darken-2'"
            >
              <v-icon>{{ mdiCreditCardSettingsOutline }}</v-icon>
            </v-btn>
            <v-btn
              fab
              dark
              x-small
              :color="item.orcamentoId ? 'primary darken-2' : 'grey darken-2'"
              @click="orcar(item)"
              :title="item.orcamentoId ? 'Orçamento' : 'Orçar'"
              ><v-icon dark> {{ mdiCurrencyBrl }} </v-icon>
            </v-btn>
          </v-speed-dial>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination
          v-model="pageable.page"
          :length="pageable.totalPages"
          @input="changePage"
        ></v-pagination>
      </div>
    </v-card>
    <v-dialog
      v-model="orcamentoDialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="1200px"
      fullscreen
      hide-overlay
    >
      <v-card>
        <v-card-title
          >Orcamento: # {{ projeto.orcamentoId }} > Projeto:
          {{ projeto.projeto && projeto.projeto.nome }}
          <v-spacer></v-spacer>

          <v-btn icon @click="orcamentoDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <OrcamentoForm
            :projeto="projeto"
            v-if="orcamentoDialog"
            @sucesso="inicializar"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="atividadeBasicaDialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="800px"
      :fullscreen="mobile"
    >
      <v-card>
        <v-card-title
          >Custos Extras
          <v-spacer></v-spacer>

          <v-btn icon @click="atividadeBasicaDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <AtividadeForm
            v-if="atividadeBasicaDialog"
            :projeto="projeto.projeto"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="projetoDialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-card-title
          >Adiciona Projeto
          <v-spacer></v-spacer>

          <v-btn icon @click="projetoDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <ProjetoForm
            v-if="projetoDialog"
            :clientes="clientes"
            :projeto="projeto"
            :orcamento="orcamento"
            @sucesso="inicializar"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="parcelaPagamentoDialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-card-title
          >Forma Pagamento Mensal
          <v-spacer></v-spacer>
          <v-btn icon @click="parcelaPagamentoDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <ParcelaPagamento
            v-if="parcelaPagamentoDialog"
            :payload="parcelaPagamentoPayload"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import Projeto from "@/service/projeto.js";
import Cliente from "@/service/cliente";
import Relatorio from "@/service/relatorio";
import ParcelaPagamento from "@/views/parcelasPagamentoProjeto";
import { saveAs } from "file-saver";
import {
  mdiPlus,
  mdiPencil,
  mdiCurrencyBrl,
  mdiCashMultiple,
  mdiFileDocumentEditOutline,
  mdiEmailSendOutline,
  mdiCreditCardSettingsOutline,
  mdiClose,
} from "@mdi/js";
import OrcamentoForm from "@/views/orcamento/Form";
import AtividadeForm from "@/views/atividade_basica/Form";
import ProjetoForm from "./Form";
export default {
  components: {
    OrcamentoForm,
    AtividadeForm,
    ProjetoForm,
    ParcelaPagamento,
  },
  data() {
    return {
      fab: true,
      mdiPlus,
      mdiCurrencyBrl,
      mdiCashMultiple,
      mdiPencil,
      mdiFileDocumentEditOutline,
      mdiEmailSendOutline,
      mdiCreditCardSettingsOutline,
      mdiClose,
      orcamentoDialog: false,
      atividadeBasicaDialog: false,
      projetoDialog: false,
      projetos: [],
      projeto: {},
      options: {},
      pageable: {},
      headers: [
        {
          text: "#",
          value: "projeto.id",
          align: "start",
          filterable: false,
          sortable: false,
        },
        {
          text: "Nome",
          value: "projeto.nome",
          filterable: false,
          sortable: false,
        },
        {
          text: "Cliente",
          value: "projeto.cliente.nome",
          filterabldatae: false,
          sortable: false,
        },
        {
          text: "Valor do Cliente",
          value: "valorTotalCliente",
          filterabldatae: false,
          sortable: false,
        },
        {
          text: "Valor Realizado",
          value: "valorTotalRealizado",
          filterabldatae: false,
          sortable: false,
        },
        {
          text: "Valor Real",
          value: "receita",
          filterabldatae: false,
          sortable: false,
        },
        {
          text: "Pagamento Cliente",
          value: "parcelasRecebidas",
          filterabldatae: false,
          sortable: false,
        },
        {
          text: "Pagamento Fornecedor",
          value: "parcelasPagas",
          filterabldatae: false,
          sortable: false,
        },
        {
          text: "Aceito",
          value: "aceito",
          filterabldatae: false,
          sortable: false,
          align: "center",
        },
        {
          text: "Finalizar",
          value: "actions",
          sortable: false,
          filterable: false,
          align: "top",
        },
        {
          text: "Ações",
          value: "atividadesBasicas",
          sortable: false,
          filterable: false,
          align: "center",
        },
      ],

      loading: false,
      clientes: [],
      parcelaPagamentoPayload: {},
      parcelaPagamentoDialog: false,
      orcamento: { id: null, aceito: false },
    };
  },

  computed: {
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  methods: {
    getColor(valor1, valor2) {
      if (!valor1) return "#C63937";

      if (valor1 == valor2) return "#397a1f";

      if (valor1 > 0 && valor1 < valor2) return "#f9a825";

      return "#000000";
    },
    async finalizar(item) {
      console.log(item);
      try {
        this.loading = true;
        await Projeto.finalizar({
          id: item.projeto.id,
          finalizado: item.projeto.finalizado,
        });
        this.inicializar({
          page: this.pageable.page - 1,
        });
        if (item.projeto.finalizado) {
          this.$notify({
            title: "Sucesso",
            text: "Projeto finalizado!",
            type: "success",
          });
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    async excluir(item) {
      const res = await this.$confirm(
        "A exclusão do projeto não poderá ser desfeita?",
        {
          title: "Tem certeza?",
          buttonTrueText: "SIM",
          buttonFalseText: "NÃO",
          color: "error",
        }
      );
      if (res) {
        try {
          this.loading = true;
          await Projeto.delete({ id: item.projeto.id });
          this.inicializar();
          this.$notify({
            title: "Sucesso",
            text: "Projeto excluido com sucesso!",
            type: "success",
          });
          this.loading = false;
        } catch (error) {
          this.loading = false;
          console.log(error);
        }
      }
    },
    abreFormaPagamentoMensal(item) {
      this.parcelaPagamentoPayload = {
        projetoId: item.projeto.id,
      };

      this.parcelaPagamentoDialog = true;
    },
    async enviarEmail(item) {
      if (!item.projeto.dataPagamento) {
        this.$notify({
          title: "Por favor",
          text: "Insira uma data de vencimento do projeto.",
          type: "info",
        });
        return;
      }
      const payload = {
        projetoId: item.projeto.id,
        tipo: "projetocores",
      };
      this.loading = true;
      try {
        await Relatorio.sendEmail(payload);
        this.loading = false;
        this.$notify({
          title: "Sucesso",
          text: "Dados salvos!",
          type: "success",
        });
      } catch (error) {
        this.loading = false;
        this.$notify({
          title: "Erro",
          text: error.title,
          type: "error",
        });
      }
    },
    editar(item) {
      this.projeto = item.projeto;
      console.log(item);
      this.orcamento = {
        id: item.orcamentoId,
        aceito: item.aceito,
      };
      this.projetoDialog = true;
    },
    changePage(value) {
      this.pageable.page = value;
      this.inicializar({ page: value - 1 });
    },
    async orcar(item) {
      this.projeto = item;
      this.$nextTick(() => {
        this.orcamentoDialog = true;
      });
    },
    atividadeBasica(item) {
      this.projeto = item;
      this.atividadeBasicaDialog = true;
    },

    async inicializar(options) {
      this.projetoDialog = false;
      this.loading = true;

      const resp = await Projeto.fetchProjetos({
        finalizado: true,
        ...options,
      });
      this.projetos = resp.content;

      this.pageable = {
        totalElements: resp.totalElements,
        totalPages: resp.totalPages,
        pageSize: resp.size,
        page: resp.number + 1,
      };
      this.loading = false;
    },
    async gerarContrato(item) {
      if (!item.projeto.dataPagamento) {
        this.$notify({
          title: "Por favor",
          text: "Insira uma data de vencimento do projeto.",
          type: "info",
        });
        return;
      }

      this.loading = true;

      const payload = {
        projetoId: item.projeto.id,
        tipo: "projetocores",
      };
      try {
        const resp = await Relatorio.gerar(payload);
        this.loading = false;
        saveAs(resp, `PROJETO DE CORES - ${item.projeto.nome}.pdf`);
      } catch (error) {
        this.loading = false;
        this.$notify({
          title: "Erro",
          text: "Ocorreru um erro ao gerar o contrato",
          type: "error",
        });
      }
    },
  },
  async created() {
    this.inicializar({ page: 0 });
    this.clientes = await Cliente.findAllList();
  },
};
</script>

<style scoped></style>
